import React from "react";
import { createUseStyles } from "react-jss";
import colors from "../../constants/colors";
import classNames from "classnames";

const TextInput = React.forwardRef(
  ({ fill, className, value, onChange, ...props }, ref) => {
    const classes = useStyles();

    return (
      <input
        value={value}
        onChange={onChange}
        ref={ref}
        {...props}
        className={classNames(
          classes.input,
          fill ? classes.fill : "",
          className
        )}
      />
    );
  }
);

export default TextInput;

const useStyles = createUseStyles({
  input: {
    fontSize: 14,
    margin: 0,
    padding: "8px 15px",
    background: colors.white,
    borderRadius: 5,
    border: "1px solid #D5DDEB",

    "&::placeholder": {
      color: colors.tertiary,
    },
  },
  fill: {
    width: "100%",
  },
});

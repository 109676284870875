import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const Box = ({ children, className, ...props }) => {
  const classes = useStyles();
  return (
    <div {...props} className={classNames(classes.box, className)}>
      {children}
    </div>
  );
};

export default Box;

const useStyles = createUseStyles({
  box: {
    width: "100%",
    maxWidth: 960,
    padding: 10,
    margin: "auto",
  },
});

import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const ZStack = ({ children, className, ...props }) => {
  const classes = useStyles();
  return (
    <div {...props} className={classNames(classes.zstack, className)}>
      {children}
    </div>
  );
};

export default ZStack;

const useStyles = createUseStyles({
  zstack: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    "& *": {
      gridArea: "1/1/1/1",
    },
  },
});

import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import colors from "../constants/colors";
import Button from "../components/UI/Button";

import FlexV from "../components/Flex/FlexV";
import FlexH from "../components/Flex/FlexH";
import SectionH from "../components/Flex/SectionH";
import Box from "../components/Flex/Box";

import Text from "../components/UI/Text";
import TextInput from "../components/UI/TextInput";
import TextInputButton from "./UI/TextInputButton";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { motion } from "framer-motion";
import { Player } from "@lottiefiles/react-lottie-player";

function transformTemplate(transformProps, transformedString) {
  return `perspective(2000px) ${transformedString}`;
}

export const ContactFormSection = () => {
  const classes = useStyles();

  return (
    <SectionH
      id="request-section"
      className={classes.diagonal}
      style={{
        paddingTop: 100,
        paddingBottom: 150,
        marginBottom: -100,
      }}
    >
      <Box style={{ zIndex: 1 }}>
        <FlexH gap={25}>
          <FlexV alignment="flex-start">
            <Text size="title3" style={styles.smallTitle}>
              {"Want to know more?".toUpperCase()}
            </Text>
            <p style={styleContactForm.details}>
              Contact our sales team and we’ll get back to you as soon as
              possible with a cutomized solution for your needs.
            </p>
            <p style={styleContactForm.details}>
              We also help you with the photography setup and the code
              integration of your shop.
            </p>
            <div style={{ margin: "auto", marginTop: -40 }}>
              <Player
                autoplay
                loop
                src="/lottie/rocket.json"
                style={{
                  height: 300,
                  width: 300,
                  maxWidth: "100%",
                }}
              />
            </div>
          </FlexV>

          <RequestDemoForm />
        </FlexH>
      </Box>
    </SectionH>
  );
};

const styleContactForm = {
  details: {
    fontSize: "22px",
    color: colors.foregroundRegentGrey,
    maxWidth: 400,
  },
};

export const RequestDemoForm = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);

    const name = e.target.children.name.value;
    const email = e.target.children.email.value;
    const phone = e.target.children.phone.value;
    const companyWebsite = e.target.children.companyWebsite.value;
    const nbProducts = e.target.children.nbProducts.value;

    await fetch("https://app.elevate3d.ai/api/request-demo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        companyWebsite,
        nbProducts,
      }),
    });

    setLoading(false);
    toast.success("A demo has been requested!");

    e.target.children.name.value = "";
    e.target.children.email.value = "";
    e.target.children.phone.value = "";
    e.target.children.companyWebsite.value = "";
    e.target.children.nbProducts.value = "";
  };

  return (
    <motion.form
      id="request-form"
      style={styleRequestForm.form}
      alignment="flex-start"
      transformTemplate={transformTemplate}
      whileHover={{
        scale: 1,
        rotateX: 0,
        boxShadow:
          "0px -35px 70px rgba(54, 49, 113, 0.175), 0px 15px 25px rgba(255, 255, 255, 0.15)",
      }}
      onSubmit={handleSubmit}
    >
      <Text size="title3">Request a demo to know more</Text>

      <TextInput required fill placeholder="Name" name="name" />
      <TextInput required fill type="email" placeholder="Email" name="email" />
      <TextInput fill type="phone" placeholder="Phone" name="phone" />
      <TextInput fill placeholder="Company / Website" name="companyWebsite" />
      <TextInput
        fill
        placeholder="Approx. number of products"
        name="nbProducts"
      />

      <Button fill type="submit" loading={loading} style={{ minHeight: 35 }}>
        {"Get started".toUpperCase()}
      </Button>
      <Text>We will reply to your request as soon as we can!</Text>
    </motion.form>
  );
};

const styleRequestForm = {
  form: {
    scale: 1.01,
    rotateX: -3,
    minWidth: 400,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: 30,
    margin: 10,
    background: colors.white,
    borderRadius: 40,
    boxShadow:
      "0px -25px 50px rgba(54, 49, 113, 0.15), 0px 15px 25px rgba(255, 255, 255, 0.15)",
  },
};

const useStyles = createUseStyles({
  diagonal: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: colors.backgroundLightBlue,
      transform: "skewY(-6deg)",
    },
  },
  diagonalWhite: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: colors.white,
      transform: "skewY(-6deg)",
    },
  },
  diagonalDarkMode: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: "#040410",
      transform: "skewY(-6deg)",
    },
  },
  presentationTable: {
    tableLayout: "fixed",
    textAlign: "center",
    wordBreak: "break-word",
    "& td": {
      width: "25%",
      border: "1px solid #D1D1D1",
      padding: 15,
    },
    "& td:first-child": {
      borderLeft: "none",
    },
    "& tr:first-child td": {
      borderTop: "none",
    },
    "& tr:nth-last-child(2) td": {
      borderBottom: "none",
    },
    "& td:nth-last-child(2)": {
      borderRight: "none",
    },
    "& td:last-child": {
      backgroundColor: "rgba(127, 173, 196, 0.27)",
      borderLeft: "3px solid #9098BD",
      borderRight: "3px solid #9098BD",
    },
    "& tr:first-child td:last-child": {
      borderStartStartRadius: 10,
      borderStartEndRadius: 10,
      borderTop: "3px solid #9098BD",
    },
    "& tr:nth-last-child(2) td:last-child": {
      borderEndStartRadius: 10,
      borderEndEndRadius: 10,
      borderBottom: "3px solid #9098BD",
    },
    "& tr:last-child td": {
      border: "none",
      background: "none",
      padding: 0,
      paddingTop: 10,
    },
  },
});

let styles = {
  smallTitle: {
    fontWeight: 600,
    color: colors.primary,
    textTransform: "uppercase",
    letterSpacing: "0.03em",
    margin: 0,
  },
  smallTitleDarkMode: {
    fontWeight: 600,
    color: "#C6C6C6",
    textTransform: "uppercase",
    letterSpacing: "0.03em",
    margin: 0,
  },
  title: {
    color: colors.black,
    fontWeight: 600,
    margin: "10px 0",
  },
  titleDarkMode: {
    color: colors.white,
    fontWeight: 600,
    margin: "10px 0",
  },
};

export const RequestDemoInput = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (loading) return;

    setLoading(true);
    const res = await fetch("https://app.elevate3d.ai/api/request-demo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });

    setLoading(false);
    toast.success("You're on the waitlist!");
    setEmail("");
  };

  return (
    <TextInputButton
      autoFocus
      value={email}
      loading={loading}
      onChange={handleChange}
      type="email"
      required
      placeholder="Enter your email address"
      onSubmit={handleSubmit}
      inputStyle={{ flex: 1, minHeight: 40 }}
      buttonStyle={{
        minWidth: 150,
        minHeight: 35,
        backgroundColor: "#E56FBC",
        borderColor: "#EB49B2",
      }}
      style={{
        margin: "auto",
        marginBottom: 40,
        height: 45,
        alignItems: "stretch",
        maxWidth: 600,
      }}
    >
      {"Join the waitlist now!".toUpperCase()}
    </TextInputButton>
  );
};

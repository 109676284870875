import React from "react";
import FlexH from "../Flex/FlexH";
import FlexV from "../Flex/FlexV";
import TextInput from "./TextInput";
import Button from "./Button";
import { useMediaQuery } from "react-responsive";

const TextInputButton = ({
  children,
  value,
  onChange,
  inputStyle,
  buttonStyle,
  style,
  autoFocus,
  loading,
  onSubmit,
  ...props
}) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      {isSmallScreen ? (
        <FlexV gap={5} style={style}>
          <TextInput
            value={value}
            onChange={onChange}
            fill
            autoFocus={autoFocus}
            style={inputStyle}
            {...props}
          />
          <Button
            fill
            loading={loading}
            type="submit"
            noAnim
            style={buttonStyle}
            {...props}
          >
            {children}
          </Button>
        </FlexV>
      ) : (
        <FlexH gap={0} style={style}>
          <TextInput
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              ...inputStyle,
            }}
            {...props}
          />
          <Button
            loading={loading}
            type="submit"
            noAnim
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              ...buttonStyle,
            }}
            {...props}
          >
            {children}
          </Button>
        </FlexH>
      )}
    </form>
  );
};

export default TextInputButton;

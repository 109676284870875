import Layout from "../components/Layout/Layout";
import SEO from "../components/Layout/SEO";
import SectionV from "../components/Flex/SectionV";
import Box from "../components/Flex/Box";
import ReactMarkdown from "react-markdown";

const terms = `
# General Terms and Conditions of Use of Elevate3D
Last Update: April 12th 2022

Please read carefully these Terms of Use (“Terms”) before using the services offered by Elevate3D.
These Terms constitute a legally binding agreement between Elevate3D, GmbH (also “Elevate3D”, “we”, “us”) and
User (also “you”, “your”), which regulates your use of the Elevate3D service (“Service”)
and other services as provided herein.

If Elevate3D provides you with the Services through the website, you agree to be bound by these Terms and the Privacy Policy
by using the website.

If you do not agree to these Terms, do not access or use our Services.

### 1. Description of Services
Elevate3D is an a visualization service for online shopping enabling to view products in 3D within a browser (“Services”).
You may use Elevate3D immediately with no registration form being submitted.
 
Scope of the Services. Elevate3D reserves the right to change the Services scope listed herein and change/introduce prices
and fees applicable to the Services at any time for any reason in its sole discretion and without notice.
Elevate3D is entitled to stop or restrict provision of the Services in full or in part toward a certain user.
Elevate3D retains powers to discontinue provision and/or support of the Services without any prior notice.


### 2. Who can use the Services
You must be at least 13 years of age to access or use our Services. If you are under 18 years of age (or the age of legal
majority where you live), you may only access or use our Services under the supervision of a parent or legal guardian who
agrees to be bound by these Terms. If you are a parent or legal guardian of a user under the age of 18 (or the age of legal
majority), you agree to be fully responsible for the acts or omissions of such user in connection with our Services.

By using the Services, you state that: you can form a binding contract with Elevate3D, GmbH, meaning that you either are
over the age of 18 or you use the Services under a parent’s or legal guardian’s supervision; you are not a person barred
from using the Services under the laws of the United States, the European Union (including the laws of the EU member states)
or any other applicable jurisdiction; you will comply with these Terms and all applicable local, state, national,
and international laws, rules, and regulations.


### 3. Elevate3D Content
Our Services and the text, graphics, images, illustrations, trademarks, trade names, page headers, button icons, 
scripts and other content contained therein are owned by Elevate3D or its subsidiaries or affiliated companies,
and is protected by copyright, patent, trade secret and other intellectual property laws. Except as explicitly stated
in these Terms, Elevate3D reserves all rights in and to our Services.

Elevate3D hereby grants you a limited, worldwide, royalty-free, non-exclusive, non-transferable, non-sublicensable,
revocable license to access and use the Services. This license is for the sole purpose of letting you use and enjoy the
Services’ benefits in a way that these Terms allow.

Such license does not include any right to: 
- (a) sell or commercially use any part of our Services; 

- (b) copy, reproduce, distribute, publicly perform or publicly display any part of our Services; 

- (c) modify any part of our Services, remove any proprietary rights notices; 

- (d) reverse engineer or attempt to extract the source code of that software; 

- (e) use our Services other than as expressly provided in these Terms. The license does not cover any GIFs or videos possessed by
any third parties and used by Elevate3D to provide the Services. You shall use them as stipulated in chapter 4 hereof.

Any use of our Services other than as specifically authorized herein, without our prior written permission, is strictly
prohibited and will terminate the license granted under these Terms.

### 4. User Content
Our Services may allow you to use the Services with the Uploaded content, as well as to create, post, store and share the Generated content.
The Uploaded and the Generated content is your intellectual property.
Except for the license you grant below, you retain all rights in and to your content.
Elevate3D does not claim ownership of any user content.
 
You hereby grant Elevate3D a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to host, store,
use in any way, display, reproduce, modify, adapt, edit, publish, and distribute Uploaded and Generated content.
This license is for the limited purpose of operating, developing, providing, and improving the Services,
and displaying Uploaded content in the library for the User’s repeated use.
 
The Generated content may be public, so the license you grant us for this content is broader.
In addition to granting us the rights mentioned in the previous paragraph, you also grant us a perpetual license to
create derivative works from, exhibit, broadcast, publicly perform, and publicly display the Generated content in any
form and in any and all media or distribution methods.
 
You acknowledge and agree that we may generate revenues, increase goodwill or otherwise increase our value from your use
of the Application, including, but not limited to, through the sale of advertising, sponsorships, promotions, usage data.
You further acknowledge that, except as specifically permitted by us in these Terms or in another agreement you enter
into with us, you
- (i) have no right to receive any income or other consideration from any user content or your use of any materials made
available to you on or through the Application, including in any user content created
by you;

- (ii) are prohibited from exercising any rights to monetize or obtain consideration from any User Content within
the Services or on any third party service.
 
You warrant that your content does not and will not violate third-party rights of any kind, including without limitation
any intellectual property rights or rights of privacy or publicity. You hereby represent that you are the owner of the
copyright with respect to all your content and have the power to grant the license to Elevate3D as set forth herein.
 
You hereby acknowledge that you are solely responsible for the Uploaded content and Generated content, as well as any
consequences of publicly posting and sharing such content, including, but not limited to any claims, costs, losses,
damages, expenses, judgments, any other possible conflicts, disputes, contretemps or issues arising out of or related to your content.
 
You hereby acknowledge that you are entitled to delete any of the Uploaded content from your profile gallery, but it
shall not cause revoking of the license granted to Elevate3D.
 
If you share the Generated content publicly through the Services or in any other way, you acknowledge that such content
will be accessible to others. Any content will be considered non-confidential and non-proprietary.
You must not post any content on or through the Services or transmit to us any content that you consider to be confidential
or proprietary.
Please do not publicly post or submit any user content that you do not want to be publicly accessible or viewable, or
that you do not have rights to post.


### 5. Prohibited Conduct and Content
You may only use the Services and its contents for lawful purposes. You will not violate any applicable law, contract,
intellectual property or other third-party right. You are solely responsible for your conduct while accessing or using
our Services, as well as for all content you upload, share or otherwise transmit to or via the Services.
 
While using the Services you may not, and may not encourage, authorize, or assist others to:
 
- (a) engage in any harassing, threatening, intimidating, predatory or stalking conduct;
 
- (b) use our Services in any manner that could interfere with, disrupt, negatively affect or inhibit other users from
fully enjoying our Services or that could damage, disable, overburden or impair the functioning of our Services in any manner
 including by submitting a virus, worm, or Trojan horse;
 
- (c) reverse engineer, decompile, disassemble, or in any way access or attempt to access the source code of the Services
or attemptto study or test the vulnerability of the Services or to breach any security measures, regardless of your motives and/or intent;
 
- (d) attempt to circumvent any measures employed to limit access to any part of our Services, or attempt to access any
feature or area of our Services that you are not authorized to access;
 
- (e) develop or use any third-party applications that interact with our Services without our prior written consent,
including any scripts designed to scrape or extract data from our Services, or intercept any system data, personal information,
or other data relating to the Services,
 
- (f) use our Services for any illegal or unauthorized purpose, or engage in, encourage or promote any activity that violates these Terms.
 
You may not upload, share or otherwise transmit to or via the Services any content that:
 
- (a) is unlawful, harmful, libelous, defamatory, obscene, abusive, racially or ethnically offensive, pornographic,
indecent, lewd, harassing, threatening, invasive of personal privacy or publicity rights, or otherwise objectionable;
 
- (b) would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party or
otherwise create liability or violate any local, state, national or international law;
 
- (c) may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of any party;
 
- (d) contains any unsolicited promotions, political campaigning, advertising or solicitations;
 
- (e) contains any private or personal information of a third party without such third party’s consent;
 
- (f) may harm or exploit children by exposing them to inappropriate content, asking for personally identifiable details or otherwise;
 
- (g) contains any viruses, corrupted data or other harmful, disruptive or destructive files or content,
designed to interrupt, destroy or limit the functionality of the Application;
 
- (h) contains any information or content that you do not have a right to make available under any law or under contractual
or fiduciary relationships (e.g., inside information, confidential information received in the context of an employment
or a non-disclosure agreement);
 
- (i) is, in our sole judgment, objectionable or that restricts or inhibits any other person from using or enjoying our
Services, or that may expose Elevate3D or others to any harm or liability of any type.
 
Elevate3D reserves the right, but is not obligated, to reject and/or remove any user content that Elevate3D believes, in its
sole discretion, violates these provisions.

### 6. Monitoring of Content
Elevate3D reserves the right to monitor all user content posted on/submitted to the Services.
 
Elevate3D may at its sole discretion remove any user content, including, but not limited to the content that contains
any illegal content, viruses, spyware and malware, obscene or pornographic materials, libelous, defamatory, violent or
hate-oriented content, promotes any commercial activities, or any other content that may harm Elevate3D, its business or
reputation.
 
Without limiting the foregoing, Elevate3D may remove any material that Elevate3D, in its sole discretion, finds to be in
violation of these Terms or otherwise objectionable.
 
If you have noticed any violation of these Terms and/or objective, from your prospective, content of any nature
whatsoever, please contact us at [hello@elevate3d.ai](mailto:hello@elevate3d.ai) or use in-app report form.



### 7. Copyright complaints
If you believe that any text, graphics, photos, audio, videos or other materials or works uploaded, downloaded or appearing
on the Application have been copied in a way that constitutes copyright infringement, you may submit a notification to
our copyright agent in accordance with 17 USC 512(c) of the Digital Millennium Copyright Act (the “DMCA”),
by providing the following information in writing:

- (a) identification of the copyrighted work that is claimed to be infringed;

- (b) identification of the allegedly infringing material that is requested to be removed, including a description of where
it is located on the Application;

- (c) information for our copyright agent to contact you, such as an address, telephone number and e-mail address;

- (d) a statement that you have a good faith belief that the identified, allegedly infringing use is not authorized by
the copyright owners, its agent or the law; 

- (e) a statement that the information above is accurate, and under penalty of perjury, that you are the copyright owner or
the authorized person to act on behalf of the copyright owner;

- (f) the physical or electronic signature of a person authorized to act on behalf of the owner of the copyright or of an
exclusive right that is allegedly infringed.

It is our policy, in appropriate circumstances and at our discretion, to disable or terminate the accounts of users who
repeatedly infringe copyrights or intellectual property rights
of others.

A user of the Application who has uploaded or posted materials identified as infringing as described above may supply a
counter-notification pursuant to sections 512(g)(2) and (3) of the DMCA. When we receive a counter-notification, we may
reinstate the posts or material in question, in our sole discretion. To file a counter-notification with us, you must
provide a written communication (by regular mail or by email) that sets forth all of the items required by sections
512(g)(2) and (3) of the DMCA. Please note that you will be liable for damages if you materially misrepresent that
content or an activity is not infringing the copyrights of others. 



### 8. Indemnification
You hereby agree to indemnify Elevate3D, any of its officers, directors, employees and agents and its affiliated and
related entities from and against any claims, costs, losses, liabilities, damages, expenses and judgments of any and
every kind arising out of, relating to, or incurred in connection with any claim, complaint, audit, inquiry, or other
proceeding, that arises or relates to:

- (a) any actual or alleged breach of your representations, warranties, or
obligations set forth in these Terms;

- (b) your wrongful or improper use of the Services or the information contained
herein;

- (c) any other party’s access or use of the Services with your information.



### 9. Privacy
Elevate3D respects your privacy and has established certain policies and procedures relating to the collection and use
of your personal information. Please check our [Privacy Policy](/privacy) to be aware of how we collect, use and
share your personal information when you use our Services.



### 10. Disclaimer of Warranties
You expressly understand and agree that you access to and use of the Services at your own risk.
The Services are provided on “as is” and “as available” basis. Elevate3D expressly disclaims all warranties of any kind,
whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a
particular purpose, and non-infringement. Elevate3D makes no warranty that the Services will be uninterrupted, timely,
or error free, and the Services will be available at all times, in all countries and/or all geographic locations.


### 11. Limitation of Liability
Except as otherwise required by law, in no event shall Elevate3D, our directors, members, employees or agents be liable
for any special, indirect or consequential damages, or any other damages of any kind, including but not limited to loss
of use, loss of profits or loss of data, arising out of or in any way connected with the use of or inability to use our
Services, regardless of the basis upon which liability is claimed (breach of contract, strict liability, failure of
essential purpose, or otherwise) and even if Elevate3D has been advised of the possibility of such loss or damage.
 
Elevate3D does not accept any responsibility related to the operation of any software, the presence of viruses or other
malicious code elements, dangerous or destructive files, which can spread or in any other way affect the software and
hardware as a result of using the services by you, accessing information or downloading any content.
 
If applicable law does not allow all or any part of the above limitation of liability to apply to you, the limitations
will apply to you only to the extent permitted by applicable law. You understand and agree that it is your obligation
to ensure compliance with any legislation relevant to your country of domicile concerning the use of the Application.


### 12. Links to Third-Party websites
Elevate3D may contain links to third-party websites and services. Please note, their presence does not mean that they are
recommended by Elevate3D and Elevate3D do not guarantee their safety and conformity with any your expectations.
Elevate3D is not responsible for maintaining any materials referenced from another website, and makes no warranties for
that website or respective service. Elevate3D assumes no obligations in the event of any damage or loss, or any other impact,
directly or indirectly resulting from the use of any content, goods or services available on or through any such third-party
websites and services.


### 13. Governing Law and Dispute resolution
In the event of any dispute with Elevate3D, in relation with these General Terms and Conditions, Users have the right to
seek recourse, free of charge, to a consumer mediator, for free of charge, for purposes of finding an amicable settlement,
pursuant to articles L611-1 et seq. and articles R152-1 et seq. of the French Consumer Code.

To this end, Users may contact the following consumer mediator:
Centre de médiation et de règlement amiable des huissiers de justice (Medicys – Mediation and Amicable Resolution Center of Judicial Officers)

* Street address: 73 Boulevard de Clichy, 75009 Paris (France)
* Telephone: +33 1 49 70 15 93
* https://medicys.fr/

In the event of any claim made by a European consumer, for which no amicable solution is found with the customer service
of Elevate3D, this consumer has the right to use the European Online Dispute Resolution platform which may be accessed at
the following URL address: <https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR>


### 14. Changes to our Terms
Elevate3D reserves the right to modify, amend or otherwise change these Terms from time to time if doing so will be deemed
necessary in order to comply with the applicable laws and actual circumstances of the functioning of the Application,
so please periodically check this page to ensure that you’re satisfied with any changes.
Additional notifications on any amendments will be displayed or otherwise communicated to you when using the Services
after the amendments were made. If within one week after such notification has been shown to you, you continue to use
the Services, you will be considered as having accepted all the amendments, unless there is an obligation imposed on
Elevate3D by an applicable law to obtain your explicit consent to the amendments.



### 15. Termination
These Terms shall be valid till terminated by either Party. Notwithstanding anything contained herein, Elevate3D reserves
the right, without notice and at our sole discretion, to terminate these Terms or suspend your right to access the Application,
including (but not limited to) in case of your breach of these Terms or if Elevate3D believes you have committed fraud,
negligence or other misconduct.


### 16. Severability
These Terms shall supersede any other arrangements between the Parties as well as all prior versions thereof.
Should any provision of these Terms (a clause or a statement within a clause) be void, unenforceable or legally invalid
otherwise, it shall not affect any other provision hereof, or these Terms as a whole.

### 17. Contacts
For any additional question regarding the use of Services or regarding these Terms, please contact us
at [hello@elevate3d.ai](mailto:hello@elevate3d.ai) or write us to:

Elevate3D, GmbH  
Grabenstrasse 20, 6300 Zug
Switzerland
`;

const Terms = () => {
  return (
    <Layout>
      <SEO title="Elevate3D | Terms" />
      <SectionV>
        <Box>
          <ReactMarkdown children={terms} />
        </Box>
      </SectionV>
    </Layout>
  );
};

export default Terms;

import React from "react";
import { createUseStyles } from "react-jss";
import Flex from "./Flex";
import classNames from "classnames";

const FlexV = ({ children, className, ...props }) => {
  const classes = useStyles();
  return (
    <Flex {...props} className={classNames(classes.flexDiv, className)}>
      {children}
    </Flex>
  );
};

export default FlexV;

const useStyles = createUseStyles({
  flexDiv: {
    flexDirection: "column",
  },
});

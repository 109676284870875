export default () => {
  return (
    <svg
      width="39"
      height="35"
      viewBox="0 0 40 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.85858 13.3188L13.3884 5.81764L39.0394 0.309326L35.4025 7.22933L9.85858 13.3188Z"
        fill="#1B87C0"
      />
      <path
        d="M5.13834 24.3979L9.01685 16.5086L25.5628 12.6554L21.5424 20.6547L5.13834 24.3979Z"
        fill="#41B3EE"
      />
      <path
        d="M0 35.6909L3.87053 27.8645L29.5682 21.4604L25.6738 29.4756L0 35.6909Z"
        fill="#67C9FD"
      />
    </svg>
  );
};

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Alert from "./Alert";
import { useMediaQuery } from "react-responsive";
import { UAParser } from "ua-parser-js";
import ZStack from "./Flex/ZStack";
import FlexH from "./Flex/FlexH";
import Button from "./UI/Button";
import colors from "../constants/colors";
import FlexV from "./Flex/FlexV";
import Text from "./UI/Text";

import categories from "../constants/products";
import filenames from "../constants/filenames";

import { Helmet } from "react-helmet";

const defaultSize = 300;
const visualizerSize = 450;

export const Visualizer = ({
  enabled,
  productId,
  onClick,
  width = defaultSize,
  height = defaultSize,
  urlParams = "",
  borderRadius = 0,
  transparent = false,
  prefetch = true,
  style,
}) => {
  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${width + 60}px)`,
  });

  const size = isSmallScreen ? height : Math.min(width, height);
  const scale = visualizerSize / size;

  return (
    <div
      style={{
        width: isSmallScreen ? "100%" : width,
        height,
        position: "relative",
        ...styles.selectable,
        ...style,
      }}
      onClick={onClick}
    >
      {(!transparent || !enabled) && (
        <img
          draggable={false}
          className="unselectable"
          src={`https://visualize.elevate3d.ai/models/${productId}/preview.jpg`}
          style={{
            ...styles.fill,
            position: "absolute",
            top: 0,
            left: 0,
            objectFit: isSmallScreen ? "cover" : "contain",
          }}
        />
      )}
      <div style={{ position: "relative", ...styles.fill, overflow: "hidden" }}>
        {enabled ? (
          <iframe
            src={`https://visualize.elevate3d.ai/models/${productId}/${urlParams}`}
            frameBorder="0"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            execution-while-out-of-viewport="false"
            execution-while-not-rendered="true"
            web-share="true"
            style={{
              ...styles.iframe,
              height: height * scale,
              width: isSmallScreen ? `${scale * 100}%` : width * scale,
              transformOrigin: "top left",
              transform: `scale(${1 / scale})`,
              borderRadius,
            }}
          />
        ) : (
          <motion.div whileHover="hover" style={styles.fill}>
            <ZStack
              style={{
                alignItems: "center",
                justifyItems: "center",
                ...styles.fill,
              }}
            >
              <motion.img
                src="res/3d_button.svg"
                width={85}
                height={85}
                variants={{ hover: { scale: 1.1 } }}
              />
            </ZStack>
          </motion.div>
        )}
      </div>

      {prefetch && (
        <Helmet>
          {filenames.map((filename) => (
            <link
              key={productId}
              rel="prefetch"
              href={`https://visualize.elevate3d.ai/models/${productId}/${filename}`}
            />
          ))}
        </Helmet>
      )}
    </div>
  );
};

const VisualizerSlider = ({ selectedId, setSelectedId }) => {
  const [selectedCategory, setSelectedCategory] = useState(
    Object.keys(categories)[0]
  );
  const [supported, setSupported] = useState(true);

  useEffect(() => {
    const handleNotSupported = (event) => {
      if (event.data !== "string") return;

      try {
        const data = JSON.parse(event.data);
        if (data.message === "not_supported") setSupported(false);
      } catch {}
    };

    window.addEventListener("message", handleNotSupported);

    return () => window.removeEventListener("message", handleNotSupported);
  }, []);

  const isIOS14 = () => {
    const parser = new UAParser();
    const { os } = parser.getResult();
    if (os.name !== "iOS") return false;

    const majorVersion = parseInt(os.version.split(".")[0]);
    return majorVersion <= 14;
  };

  const handleCategoryClick = (categoryName) => {
    setSelectedId(null);
    setSelectedCategory(categoryName);
  };

  return (
    <FlexV
      gap={20}
      style={{
        width: "100%",
      }}
    >
      <Text size="title3" weight="bold">
        Try the visualizer!
      </Text>

      <FlexH gap={25} style={{ marginTop: -30 }}>
        {categories[selectedCategory].productIds.map((productId) => (
          <Visualizer
            productId={productId}
            enabled={selectedId === productId}
            key={productId}
            onClick={() => setSelectedId(productId)}
          />
        ))}
      </FlexH>
      <AnimatePresence>
        {!supported && (
          <motion.div
            initial={{ opacity: 0, x: 300 }}
            animate={{ opacity: 1, x: 0 }}
            style={{ margin: 15 }}
          >
            <Alert fill>
              WebGL2 is not supported on{" "}
              {isIOS14() ? "iOS 14 and older" : "older devices"}. A slideshow is
              shown as fallback solution.
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
      <FlexH gap={20}>
        {Object.entries(categories).map(([categoryName, category]) => (
          <Button
            rounded={20}
            paddingHorizontal={30}
            paddingVertical={8}
            secondary={categoryName !== selectedCategory}
            color={
              categoryName === selectedCategory
                ? category.color
                : colors.secondary
            }
            onClick={() => handleCategoryClick(categoryName)}
            key={categoryName}
          >
            {categoryName}
          </Button>
        ))}
      </FlexH>
    </FlexV>
  );
};

export default VisualizerSlider;

const styles = {
  // img: {
  //   width: imgSize,
  //   userSelect: "none",
  //   display: "inline-block",
  //   verticalAlign: "middle",
  //   imageRendering: "crisp-edges",
  // },
  selectable: {
    cursor: "pointer",
  },
  iframe: {
    userSelect: "none",
  },
  fill: {
    width: "100%",
    height: "100%",
  },
};

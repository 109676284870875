import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import colors from "../constants/colors";

import Modal from "../components/Modal";
import Clickable from "../components/UI/Clickable";
import Button from "../components/UI/Button";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Layout/SEO";
import FlexV from "../components/Flex/FlexV";
import FlexH from "../components/Flex/FlexH";
import SectionH from "../components/Flex/SectionH";
import Box from "../components/Flex/Box";
import SectionV from "../components/Flex/SectionV";
import Text from "../components/UI/Text";
import TextInputButton from "../components/UI/TextInputButton";
import VisualizerSlider, { Visualizer } from "../components/VisualizerSlider";

import { Link } from "react-router-dom";
import useWindowDimensions from "../utils/useWindowDimensions";

import { useMediaQuery } from "react-responsive";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { motion } from "framer-motion";
import { Player } from "@lottiefiles/react-lottie-player";

import Elevate3DSVG from "../components/svg/Elevate3D";
import Spacer from "../components/Flex/Spacer";

import {
  ContactFormSection,
  RequestDemoInput,
} from "../components/ContactForm";

function transformTemplate(transformProps, transformedString) {
  return `perspective(2000px) ${transformedString}`;
}

export default function Home() {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });

  const [selectedId, setSelectedId] = useState("main");

  return (
    <Layout withHeader={false}>
      <SEO title="Elevate3D" />
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar />

      <MainSection selectedId={selectedId} setSelectedId={setSelectedId} />

      <Presentation selectedId={selectedId} setSelectedId={setSelectedId} />

      <HowItWorks />

      {/* <WhyElevate3D /> */}

      <EcommerceSection />

      <MuseumSection />

      {/* <GamingSection /> */}

      <ContactFormSection />
    </Layout>
  );
}

const MainSection = ({ selectedId, setSelectedId }) => {
  const isTinyScreen = useMediaQuery({ query: "(max-width: 475px)" });
  const isSmallSreen = useMediaQuery({
    query: "(max-width: 600px), (max-height: 1000px)",
  });
  const isMediumSreen = useMediaQuery({ query: "(max-width: 900px)" });

  const scrollToPresentation = () => {
    const div = document.querySelector("#presentation");
    div.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollToForm = () => {
    const form = document.querySelector("#request-section");
    form.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <SectionV
      style={{
        ...styleMain.section,
        backgroundColor: colors.backgroundDarkBlue,
        paddingBottom: 0,
        overflow: "hidden",
        zIndex: 1,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -20,
          left: -20,
          width: "calc(100% + 40px)",
          height: "calc(100% + 40px)",
          filter: "blur(10px)",
          backgroundImage: "url(/res/bg.jpg)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: 0,
          overflow: "hidden",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          bottom: -2,
          left: -2,
          width: "calc(100% + 4px)",
          height: "100%",
          backgroundImage: "url(/res/table_bg.png)",
          backgroundSize: "contain",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          filter: "blur(2px)",
        }}
      ></div>
      <Box
        style={{
          paddingBottom: 0,
        }}
      >
        <div style={{ position: "relative" }}>
          <FlexV gap={10} style={{ alignItems: "flex-start" }}>
            <FlexH gap={30} style={{ width: "100%" }}>
              <Link style={styleMain.logoText} to="/">
                <div style={styleMain.logoImg}>
                  <Elevate3DSVG />
                </div>

                <span className="medium">Elevate3D</span>
              </Link>

              <Spacer />

              {!isTinyScreen && (
                <Link style={{ color: colors.white }} to="/pricing">
                  Pricing
                </Link>
              )}

              {!isTinyScreen && (
                <Button
                  secondary
                  color="#CC85B3"
                  style={{
                    color: "white",
                    backgroundColor: "rgba(255, 255, 255, 0.075)",
                    backdropFilter: "blur(10px)",
                  }}
                  onClick={scrollToForm}
                >
                  {"Talk to Sales".toUpperCase()}
                </Button>
              )}
            </FlexH>

            <Text
              size="title1"
              weight="medium"
              style={{ color: colors.white, lineHeight: 1.6 }}
            >
              The world’s most advanced
              {!isSmallSreen ? <br /> : " "}
              3D digitization technology.
              {!isSmallSreen && (
                <>
                  <br />
                  For unmatched levels of realism.
                </>
              )}
            </Text>

            <RequestDemoInput />
          </FlexV>

          <FlexV gap={10} style={{ marginTop: isSmallSreen ? 5 : 25 }}>
            <div
              style={{
                background: colors.white,
                padding: 10,
                boxShadow:
                  "0px 0px 30px rgba(0, 0, 0, 0.15), 0px 0px 70.5729px rgba(255, 255, 255, 0.53), 0px 0px 70.5729px rgba(0, 0, 0, 0.4), inset 42.3438px -47.0486px 94.0972px rgba(105, 95, 123, 0.12), inset -23.5243px 47.0486px 94.0972px rgba(171, 83, 210, 0.16)",
                borderRadius: 70,
                width: 800 + 2 * 10,
                maxWidth: "100%",
              }}
            >
              <Visualizer
                enabled={selectedId === "main"}
                onClick={() => setSelectedId("main")}
                productId="6324aa44f20635c60adfbbb9"
                width={800}
                height={isSmallSreen ? 350 : isMediumSreen ? 400 : 500}
                transparent={true}
                urlParams="?transparent&autoZoom&autoZoomSpeed=16&autoRotateSpeed=-8"
                borderRadius={70}
                style={{ borderRadius: 70, overflow: "hidden" }}
              />
            </div>

            {!isSmallSreen ? (
              <img
                className="button"
                height={50}
                width={50}
                src="res/arrow.svg"
                style={{ marginBottom: 10 }}
                onClick={scrollToPresentation}
              />
            ) : (
              <div style={{ marginTop: 20, marginBottom: 20 }}></div>
            )}
          </FlexV>
        </div>
      </Box>
    </SectionV>
  );
};

const styleMain = {
  section: {
    position: "relative",
  },
  logoText: {
    fontSize: 26,
    padding: "10px 0",
    display: "flex",
    alignItems: "center",
    color: colors.white,
  },
  logoImg: {
    marginRight: 10,
    height: 35,
  },
  background: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: "33%",
    overflow: "hidden",
  },
};

const Presentation = ({ selectedId, setSelectedId }) => {
  const classes = useStyles();

  const [openComparision, setComparisionOpen] = useState(false);
  const [openTech, setTechOpen] = useState(false);

  const cross = <img src="/res/cross.png" width={17} height={17} />;
  const check = <img src="/res/check.png" width={22} height={22} />;

  const scrollTo = (sectionId) => {
    const div = document.querySelector(sectionId);
    div.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <SectionH
      id="presentation"
      className={classes.diagonalWhite}
      style={{ paddingTop: 100, paddingBottom: 100 }}
    >
      <Box style={{ zIndex: 1 }}>
        <Text size="title2" style={styles.title}>
          Photorealistic 3D. Made easy.
        </Text>

        <p style={stylePresentation.details}>
          Elevate3D is the first commercial solution to automatically produce
          photorealistic 3D visualizations of products from 360° recordings.
        </p>

        <VisualizerSection
          selectedId={selectedId}
          setSelectedId={setSelectedId}
        />

        <FlexV gap={25} style={{ alignItems: "flex-start" }}>
          <Text size="title3" style={{ ...styles.smallTitle, marginTop: 40 }}>
            Revolutionizing 3D with AI
          </Text>

          <FlexV style={{ alignItems: "flex-start" }}>
            <p style={{ ...stylePresentation.details, marginBottom: 0 }}>
              Elevate3D uses breakthrough AI technology to achieve previously
              unseen realism at an unmatched cost.{" "}
              <a href="https://www.aiunfolded.co/ai/elevate3d" target="_blank">
                <Text
                  className="button"
                  weight="medium"
                  style={{ color: "#CC85B3" }}
                >
                  Learn more about the tech
                </Text>
              </a>
              .
            </p>

            <img
              src="/res/how_it_works.png"
              style={{ margin: "auto", maxWidth: "100%" }}
            />
            <p
              style={{
                margin: "auto",
                maxWidth: 300,
                textAlign: "center",
                fontStyle: "italic",
                marginTop: -10,
              }}
            >
              We convert your 360° recordings into a compact 3D representation.
            </p>

            <Modal open={openTech} onRequestClose={() => setTechOpen(false)}>
              <AIElevate3D />
            </Modal>
          </FlexV>

          <Text size="title3" style={{ ...styles.smallTitle, marginTop: 10 }}>
            Why Elevate3D
          </Text>

          <p style={stylePresentation.details}>
            Elevate3D achieves unmatched levels of realism compared to other
            solutions at a fraction of the cost of hand-designed 3D models.
          </p>

          <Modal
            open={openComparision}
            onRequestClose={() => setComparisionOpen(false)}
          >
            <PhotogrammetryComparision />
          </Modal>

          <table className={classes.presentationTable} cellSpacing="0">
            <tbody>
              <tr>
                <td></td>
                <td>Object Capture / Photogrammetry</td>
                <td>3D designer / freelancer</td>
                <td>
                  <span
                    style={{
                      display: "inline",
                      width: "1.5em",
                      height: "1.5em",
                      verticalAlign: "middle",
                    }}
                  >
                    <Elevate3DSVG />
                  </span>{" "}
                  <b>Elevate3D</b>
                </td>
              </tr>
              <tr>
                <td>Photorealistic</td>
                <td>{cross}</td>
                <td>{check}</td>
                <td>{check}</td>
              </tr>
              <tr>
                <td>Cost efficient</td>
                <td>{check}</td>
                <td>{cross}</td>
                <td>{check}</td>
              </tr>
              <tr>
                <td>Fast delivery</td>
                <td>{check}</td>
                <td>{cross}</td>
                <td>{check}</td>
              </tr>
              <tr>
                <td>3D model hosting</td>
                <td>{cross}</td>
                <td>{cross}</td>
                <td>{check}</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Button
                    secondary
                    color="#CC85B3"
                    rounded={0}
                    paddingHorizontal={0}
                    borderWidth={3}
                    style={{
                      minHeight: 35,
                      width: "100%",
                      backgroundColor: "white",
                      border: "3px solid #EB49B2",
                      borderRadius: 5,
                      alignSelf: "center",
                    }}
                    onClick={() => setComparisionOpen(true)}
                  >
                    <Text weight="medium">{"Compare".toUpperCase()}</Text>
                  </Button>
                </td>
                <td></td>
                <td>
                  <Button
                    secondary
                    color="#CC85B3"
                    rounded={0}
                    paddingHorizontal={0}
                    borderWidth={3}
                    style={{
                      minHeight: 35,
                      width: "100%",
                      color: "white",
                      backgroundColor: "#E56FBC",
                      border: "3px solid #EB49B2",
                      borderRadius: 5,
                    }}
                    onClick={() => scrollTo("#request-section")}
                  >
                    <Text weight="medium">
                      {"Request a demo".toUpperCase()}
                    </Text>
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>

          <Text size="title3" style={{ ...styles.smallTitle, marginTop: 10 }}>
            Applications
          </Text>

          <FlexH gap={25}>
            <Clickable onClick={() => scrollTo("#ecommerce-section")}>
              <ApplicationTile
                title="Ecommerce"
                image="res/ecommerce.png"
                background="linear-gradient(107.59deg, rgba(138, 35, 135, 0.86) 8.65%, rgba(233, 64, 87, 0.86) 49.25%, rgba(242, 113, 33, 0.86) 93.39%), #EAEAEA"
              />
            </Clickable>

            <Clickable onClick={() => scrollTo("#museum-section")}>
              <ApplicationTile
                title="Museum virtual tours"
                image="res/museum.png"
                background="linear-gradient(107.34deg, rgba(252, 70, 107, 0.95) 5.04%, rgba(63, 94, 251, 0.95) 99.22%), #EAEAEA"
              />
            </Clickable>

            {/* <Clickable onClick={() => scrollTo("#gaming-section")}>
              <ApplicationTile
                title="Gaming"
                image="res/gaming.png"
                background="linear-gradient(108deg, rgba(34, 193, 195, 0.95) 6.14%, rgba(253, 187, 45, 0.95) 98.01%), #EAEAEA"
              />
            </Clickable> */}
          </FlexH>
        </FlexV>

        <Text size="title3" style={{ ...styles.smallTitle, marginTop: 50 }}>
          Our partners
        </Text>

        <FlexV gap={25} alignment="flex-start">
          <FlexV style={{ alignSelf: "center" }}>
            <FlexH gap={20} style={{ width: "100%" }}>
              <img style={stylePartners.logoImg} src="/icons/nvidia.svg" />
              <img style={{ width: 200 }} src="/icons/aws.png" />
            </FlexH>
          </FlexV>
        </FlexV>
      </Box>
    </SectionH>
  );
};

const stylePartners = {
  logoImg: {
    width: 230,
  },
};

const ApplicationTile = ({ title, image, background }) => {
  return (
    <FlexV
      style={{
        borderRadius: 30,
        opacity: 0.95,
        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.25)",
        background: background,
        minWidth: 250,
        alignItems: "center",
        padding: "20px 0",
      }}
    >
      <Text style={{ color: "white" }}>{title}</Text>
      <img src={image} height={65} />
    </FlexV>
  );
};

const stylePresentation = {
  details: {
    fontSize: "18px",
    color: colors.secondary,
    lineHeight: "25px",
  },
};

const AIElevate3D = () => {
  const isTinyScreen = useMediaQuery({ query: "(max-width: 475px)" });

  return (
    <Box>
      <FlexV alignment="flex-start">
        <Text size="title3">AI at Elevate3D</Text>
        <Text>
          While 3D graphics have been available for web browsers for years,
          previous methods have failed to make products look realistic enough
          for online shopping. More importantly, it had been an unsolved
          challenge to automatically convert videos into photorealistic 3D
          visualizations.
        </Text>
        {!isTinyScreen && (
          <Text>
            At Elevate3D, we have solved these problems by leveraging recent
            breakthroughs in AI, known as Neural radiance fields. Our dedicated
            internal research & engineering have pushed its limits both in terms
            of quality and rendering speed, making our technology the{" "}
            <b>most advanced AI on the market</b>.
          </Text>
        )}
        <Text>
          Sounds exciting? Write us at{" "}
          <a
            href="mailto:careers@elevate3d.ai"
            style={{
              padding: 3,
              border: "1px solid aquamarine",
              borderRadius: 5,
            }}
          >
            careers@elevate3d.ai
          </a>{" "}
          to join our team!
        </Text>
        {!isTinyScreen && (
          <FlexH style={{ margin: "auto" }}>
            <Player
              autoplay
              loop
              src="/lottie/technology.json"
              style={{ height: 200, width: 300, maxWidth: "100%" }}
            />
          </FlexH>
        )}
      </FlexV>
    </Box>
  );
};

const PhotogrammetryComparision = () => {
  const isTinyScreen = useMediaQuery({ query: "(max-width: 475px)" });

  return (
    <Box>
      <Text size="title3" style={styles.smallTitle}>
        For an unmatched level of realism
      </Text>
      <p style={styleComparision.details}>
        Previous methods known as photogrammetry or 3D Object Capture fail to
        reach Elevate3D's level of realism, and make your products look dull and
        unappealing.
      </p>

      {!isTinyScreen && (
        <p style={styleComparision.details}>
          Using the latest advances in computer vision, our visualizer captures
          all the slightest details of your products, including its beautiful
          light reflections. In fact, our multi-view algorithm is even able to
          enhance the sharpness of your photos.
        </p>
      )}

      <FlexH flexWrap="no-wrap" gap={0}>
        <FlexV style={{ flex: 1, width: "50%" }}>
          <video
            autoPlay
            muted
            loop
            playsInline
            style={{ transform: "scale(0.92)", width: "100%", maxWidth: 300 }}
          >
            <source src="/videos/amethyst_rc.mp4" type="video/mp4" />
          </video>
          <Text style={{ fontStyle: "italic" }}>Photogrammetry</Text>
        </FlexV>

        <FlexV style={{ flex: 1, width: "50%" }}>
          <video
            autoPlay
            muted
            loop
            playsInline
            style={{ width: "100%", maxWidth: 300 }}
          >
            <source src="/videos/amethyst.mp4" type="video/mp4" />
          </video>
          <Text style={{ fontStyle: "italic" }}>Elevate3D</Text>
        </FlexV>
      </FlexH>
    </Box>
  );
};

const styleComparision = {
  details: {
    fontSize: "18px",
    color: colors.secondary,
    lineHeight: "25px",
  },
};

const VisualizerSection = ({ selectedId, setSelectedId }) => {
  return (
    <SectionV style={{ zIndex: 100, padding: 10, marginTop: 15 }}>
      <motion.div
        style={styleVisualizer.box}
        transformTemplate={transformTemplate}
        whileHover={{
          scale: 1.01,
          rotateX: 2.5,
        }}
      >
        <VisualizerSlider
          selectedId={selectedId}
          setSelectedId={setSelectedId}
        />
      </motion.div>
    </SectionV>
  );
};

const styleVisualizer = {
  box: {
    width: "100%",
    padding: 10,
    background: colors.white,
    borderRadius: 25,
    boxShadow:
      "0px 0px 48px 14px rgba(0, 0, 0, 0.14), 0px 0px 24px 6px rgba(255, 255, 255, 0.15)",
    flexWrap: "no-wrap",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
  },
};

const HowItWorks = ({ requestDemoInput }) => {
  const classes = useStyles();

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <SectionV
      className={classes.diagonal}
      style={{ paddingTop: 50, paddingBottom: 100, zIndex: -1 }}
    >
      <Box style={{ zIndex: 1 }}>
        {requestDemoInput || <div style={{ height: 50 }} />}

        <FlexV
          gap={isSmallScreen ? 75 : 0}
          style={{ marginTop: requestDemoInput ? 75 : 0 }}
        >
          <FlexH
            gap={isSmallScreen ? 10 : 0}
            style={{ flexDirection: isSmallScreen ? "column" : "row" }}
          >
            <FlexV alignment="flex-start">
              <Text size="title3" style={styles.smallTitle}>
                Record a 360 view
              </Text>
              <Text
                size="title2"
                style={{ ...styles.title, ...styleHowItWorks.title }}
              >
                Your usual photo setup
              </Text>
              <Text style={styleHowItWorks.details}>
                Record 360° videos using your standard product photography
                setup.
              </Text>
            </FlexV>
            <CameraAnimation />
          </FlexH>

          <FlexH
            gap={isSmallScreen ? 10 : 50}
            style={{ flexDirection: isSmallScreen ? "column-reverse" : "row" }}
          >
            <Player
              autoplay
              loop
              src="/lottie/cloud.json"
              style={{ height: 300, width: 300, maxWidth: "100%" }}
            />
            <FlexV alignment="flex-start">
              <Text size="title3" style={styles.smallTitle}>
                Upload it
              </Text>
              <Text
                size="title2"
                style={{ ...styles.title, ...styleHowItWorks.title }}
              >
                We turn it into 3D using the world's most advanced AI
              </Text>

              <Text
                style={{
                  ...styleHowItWorks.details,
                  ...styleHowItWorks.learnMore,
                }}
              >
                We are pushing the limits of photorealistic rendering to make
                your products stand out.
              </Text>
            </FlexV>
          </FlexH>

          <FlexH
            gap={isSmallScreen ? 10 : 0}
            style={{ flexDirection: isSmallScreen ? "column" : "row" }}
          >
            <FlexV alignment="flex-start">
              <Text size="title3" style={styles.smallTitle}>
                Easy integration
              </Text>
              <Text
                size="title2"
                style={{ ...styles.title, ...styleHowItWorks.title }}
              >
                The 3D visualization is ready for your online store!
              </Text>
            </FlexV>
            <motion.img
              src="/res/panama_jack.png"
              initial={{ y: 300, rotate: -10, opacity: 0 }}
              whileInView={{
                y: 0,
                rotate: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  bounce: 0.4,
                  duration: 1.0,
                },
              }}
              viewport={{ once: true }}
              style={{ maxWidth: "100%", width: 350 }}
            />
          </FlexH>
        </FlexV>
      </Box>
      <ColoredShapes />
    </SectionV>
  );
};

const CameraAnimation = () => {
  return (
    <div style={styleHowItWorks.cameraContainer}>
      <div style={styleHowItWorks.videoContainer}>
        <video
          autoPlay
          muted
          loop
          playsInline
          style={styleHowItWorks.video}
          poster="/res/panama_jack_preview.png"
        >
          <source src="/res/panama_jack.mp4" type="video/mp4" />
        </video>
      </div>
      <img src="/res/camera.png" style={styleHowItWorks.cameraImg} />
    </div>
  );
};

const styleHowItWorks = {
  details: {
    fontSize: "22px",
    color: colors.foregroundRegentGrey,
    maxWidth: 430,
  },
  title: {
    maxWidth: 500,
  },
  learnMore: {
    cursor: "pointer",
  },
  cameraContainer: {
    height: 300,
    width: 300,
    maxWidth: "100%",
    position: "relative",
  },
  cameraImg: {
    position: "absolute",
    top: 0,
    width: "100%",
  },
  videoContainer: {
    position: "absolute",
    top: "30%",
    left: "5%",
    width: "70%",
  },
  video: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  fallback: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
};

const ColoredShapes = ({ dark = false }) => {
  const { width } = useWindowDimensions();

  const offset = (-Math.sin((6 * Math.PI) / 180) * width) / 2 - 10;

  const suffix = dark ? "_dark" : "";

  return (
    <>
      <div
        style={{
          background: `url(/res/shape_top${suffix}.svg) no-repeat`,
          position: "absolute",
          top: offset,
          right: -30,
          width: 219,
          height: 94,
        }}
      />
      <div
        style={{
          background: `url(/res/shape_bottom${suffix}.svg) no-repeat`,
          position: "absolute",
          bottom: offset,
          left: 0,
          width: 180,
          height: 95,
        }}
      />
    </>
  );
};

const styleWhyElevate3D = {
  miniTitle: {
    fontSize: "18px",
    fontWeight: 600,
    color: colors.black,
    margin: "10px 0",
  },
  miniTitleDarkMode: {
    fontSize: "22px",
    fontWeight: 500,
    color: "#C6C6C6",
    margin: "10px 0",
  },
  details: {
    fontSize: "18px",
    color: colors.secondary,
    lineHeight: "25px",
    maxWidth: 300,
  },
  detailsDarkMode: {
    fontSize: "18px",
    color: "#C6C6C6",
    lineHeight: "25px",
    maxWidth: 300,
  },
};

const EcommerceSection = () => {
  const classes = useStyles();

  const isSmallScreen = useMediaQuery({ query: "(max-width: 800px)" });

  return (
    <SectionV
      id="ecommerce-section"
      className={classes.diagonalWhite}
      style={{
        paddingTop: 100,
        paddingBottom: 100,
        position: "relative",
      }}
    >
      <Box style={{ zIndex: 1 }}>
        <FlexH
          justify="flex-start"
          gap={50}
          style={{ flexDirection: isSmallScreen ? "column" : "row" }}
          alignment={isSmallScreen ? "center" : "flex-start"}
        >
          <FlexV alignment="flex-start">
            <Text size="title3" style={styles.smallTitle}>
              Ecommerce
            </Text>
            <Text size="title2" style={{ ...styles.title, maxWidth: 550 }}>
              The future of online shopping is here
            </Text>

            <Text style={{ ...styleWhyElevate3D.details, maxWidth: 500 }}>
              Increase your sales by letting your customers experience and
              interact with your products in 3D. Featured on{" "}
              <a href="https://www.aiunfolded.co" target="_blank">
                AI Unfolded
              </a>
              ,{" "}
              <a href="https://www.reshot.ai" target="_blank">
                Reshot AI
              </a>
              ,{" "}
              <a href="https://www.thumbnailspro.com" target="_blank">
                ThumbnailsPro
              </a>
              ,{" "}
              <a href="https://www.bricksar.com" target="_blank">
                BricksAR
              </a>
              ,{" "}
              <a href="https://www.alexcarlier.com" target="_blank">
                Alex Carlier
              </a>
              ,{" "}
              <a href="https://www.cliplaunch.com" target="_blank">
                ClipLaunch
              </a>{" "}and{" "}
              <a href="https://www.lingosub.com" target="_blank">
                LingoSub
              </a>
              . Our visualizer integrates to your online store with two clicks
              using our SDKs.
            </Text>
          </FlexV>

          <img
            src="/res/iphone.png"
            style={{ height: isSmallScreen ? 350 : 250, maxWidth: "100%" }}
          />
        </FlexH>

        <FlexH alignment="flex-start" style={{ marginTop: 40 }}>
          <FlexV alignment="flex-start">
            <img
              src="/res/engagement.png"
              style={{ height: 120, width: 120, maxWidth: "100%" }}
            />
            <Text size="title3" style={styleWhyElevate3D.miniTitle}>
              Higher customer engagement
            </Text>

            <Text style={styleWhyElevate3D.details}>
              Your customers interact with your products like in a retail store.
            </Text>
          </FlexV>

          <FlexV alignment="flex-ce">
            <img
              src="/res/increase.png"
              style={{ height: 120, width: 120, maxWidth: "100%" }}
            />
            <Text size="title3" style={styleWhyElevate3D.miniTitle}>
              Increase conversions
            </Text>

            <Text style={styleWhyElevate3D.details}>
              Customers are up to 40% more likely to make a purchase with
              Elevate3D.
            </Text>
          </FlexV>

          <FlexV alignment="flex-start">
            <img
              src="/res/returns.png"
              style={{ height: 120, width: 120, maxWidth: "100%" }}
            />
            <Text size="title3" style={styleWhyElevate3D.miniTitle}>
              Reduce returns
            </Text>

            <Text style={styleWhyElevate3D.details}>
              Returns decrease significantly when customers know what they buy.
            </Text>
          </FlexV>
        </FlexH>

        <FlexV style={{ marginTop: 60 }} alignment="flex-start">
          <Text size="title3" style={{ ...styles.smallTitle }}>
            Easy integration with your shop
          </Text>

          <FlexV gap={25} alignment="flex-start">
            <p style={{ ...stylePresentation.details, marginBottom: 0 }}>
              Seamlessly integration our visualizer into your shop. We support
              all major ecommerce platforms, and have SDKs for your codebase.
            </p>

            <FlexV style={{ alignSelf: "center" }}>
              <FlexH gap={20} style={{ width: "100%" }}>
              <a href="https://www.thumbnailspro.com" target="_blank">
                <img style={styleEcommerce.logoImg} src="/icons/shopify.png" />
                </a>
                <a href="https://www.bricksar.com" target="_blank">
                  <img style={styleEcommerce.logoImg} src="/icons/magento.png" />
                </a>
                <a href="https://www.cliplaunch.com" target="_blank">
                  <img
                    style={styleEcommerce.logoImg}
                    src="/icons/woocommerce.png"
                  />
                </a>
              </FlexH>
              <FlexH gap={20} style={{ width: "100%" }}>
                <a href="https://www.lingosub.com" target="_blank">
                  <img
                    style={styleEcommerce.logoImg}
                    src="/icons/salesforce.png"
                  />
                </a>

                <a href="https://www.aiunfolded.co" target="_blank">
                  <img
                    style={styleEcommerce.logoImg}
                    src="/icons/aiunfolded.png"
                  />
                </a>

                <a href="https://www.reshot.ai" target="_blank">
                  <img
                    style={styleEcommerce.logoImg}
                    src="/icons/angular.png"
                  />
                </a>
              </FlexH>
            </FlexV>
          </FlexV>
        </FlexV>
      </Box>
    </SectionV>
  );
};

const styleEcommerce = {
  logoImg: {
    height: 100,
  },
};

const MuseumSection = () => {
  const classes = useStyles();

  const isSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <SectionV
      id="museum-section"
      className={classes.diagonalDarkMode}
      style={{
        paddingTop: 100,
        paddingBottom: 100,
        position: "relative",
        backgroundColor: "#040410",
        color: colors.white,
        zIndex: -1,
      }}
    >
      <Box style={{ zIndex: 1 }}>
        <Text size="title3" style={styles.smallTitleDarkMode}>
          Museums
        </Text>
        <Text size="title2" style={styles.titleDarkMode}>
          Virtual tours for museums
        </Text>

        <FlexH
          gap={50}
          justify="space-around"
          style={{
            marginTop: 50,
            flexDirection: isSmallScreen ? "column" : "row",
          }}
        >
          <FlexV gap={50}>
            <FlexH gap={50}>
              <img
                src="/res/showcase_art.png"
                style={{
                  width: 130,
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
              <FlexV alignment="flex-start">
                <Text size="title3" style={styleWhyElevate3D.miniTitleDarkMode}>
                  Showcase your art digitally
                </Text>

                <Text style={styleWhyElevate3D.detailsDarkMode}>
                  Digitalize some of your art pieces and showcase them on your
                  website, social media and more!
                </Text>
              </FlexV>
            </FlexH>

            <FlexH gap={50}>
              <img
                src="/res/increase_visits.png"
                style={{
                  width: 130,
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
              <FlexV alignment="flex-start">
                <Text size="title3" style={styleWhyElevate3D.miniTitleDarkMode}>
                  Increase your visits
                </Text>

                <Text style={styleWhyElevate3D.detailsDarkMode}>
                  Let your potential visitors from around the world have a
                  virtual glimpse of your finest art, and convince them to
                  physically visit your museum!
                </Text>
              </FlexV>
            </FlexH>
          </FlexV>

          <img
            src="/res/statue.png"
            style={{ height: isSmallScreen ? 400 : 650, maxWidth: "100%" }}
          />
        </FlexH>
      </Box>
      <ColoredShapes dark />
    </SectionV>
  );
};

const GamingSection = () => {
  return <p id="gaming-section"></p>;
};

const useStyles = createUseStyles({
  diagonal: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: colors.backgroundLightBlue,
      transform: "skewY(-6deg)",
    },
  },
  diagonalWhite: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: colors.white,
      transform: "skewY(-6deg)",
    },
  },
  diagonalDarkMode: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: "#040410",
      transform: "skewY(-6deg)",
    },
  },
  presentationTable: {
    tableLayout: "fixed",
    textAlign: "center",
    wordBreak: "break-word",
    "& td": {
      width: "25%",
      border: "1px solid #D1D1D1",
      padding: 15,
    },
    "& td:first-child": {
      borderLeft: "none",
    },
    "& tr:first-child td": {
      borderTop: "none",
    },
    "& tr:nth-last-child(2) td": {
      borderBottom: "none",
    },
    "& td:nth-last-child(2)": {
      borderRight: "none",
    },
    "& td:last-child": {
      backgroundColor: "rgba(127, 173, 196, 0.27)",
      borderLeft: "3px solid #9098BD",
      borderRight: "3px solid #9098BD",
    },
    "& tr:first-child td:last-child": {
      borderStartStartRadius: 10,
      borderStartEndRadius: 10,
      borderTop: "3px solid #9098BD",
    },
    "& tr:nth-last-child(2) td:last-child": {
      borderEndStartRadius: 10,
      borderEndEndRadius: 10,
      borderBottom: "3px solid #9098BD",
    },
    "& tr:last-child td": {
      border: "none",
      background: "none",
      padding: 0,
      paddingTop: 10,
    },
  },
});

let styles = {
  smallTitle: {
    fontWeight: 600,
    color: colors.primary,
    textTransform: "uppercase",
    letterSpacing: "0.03em",
    margin: 0,
  },
  smallTitleDarkMode: {
    fontWeight: 600,
    color: "#C6C6C6",
    textTransform: "uppercase",
    letterSpacing: "0.03em",
    margin: 0,
  },
  title: {
    color: colors.black,
    fontWeight: 600,
    margin: "10px 0",
  },
  titleDarkMode: {
    color: colors.white,
    fontWeight: 600,
    margin: "10px 0",
  },
};

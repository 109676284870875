import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const Divider = ({ vertical, flex, darkMode, className, ...props }) => {
  const classes = useStyles();
  return (
    <div
      {...props}
      className={classNames(
        classes.divider,
        vertical ? (flex ? classes.verticalFlex : classes.vertical) : "",
        darkMode ? classes.darkMode : ""
      )}
    />
  );
};

export default Divider;

const useStyles = createUseStyles({
  divider: {
    width: "100%",
    height: "1px",
    boxShadow: "rgba(220, 220, 220, 0.8) -1px 0px 0px",
  },
  vertical: {
    width: "1px",
    height: "100%",
  },
  verticalFlex: {
    width: "1px",
    height: "none",
    alignSelf: "stretch",
  },
  darkMode: {
    boxShadow: "rgba(100, 100, 100, 0.8) 0px -1px 0px",
  },
});

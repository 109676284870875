export default {
  primary: "#464646",
  secondary: "#3C3C4399",
  tertiary: "#3C3C434C",
  black: "#000",
  white: "#fff",
  coreOrange: "#F77A06",
  secondaryBlue: "#2C2CD3",
  secondaryPurple: "#BF8CF3",
  backgroundLightBlue: "#E7E7F9",
  backgroundDarkBlue: "#3D387C",
  foregroundRegentGrey: "#8D8DA5",
  foregroundLightBlue: "#95A1CB",
};

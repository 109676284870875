import React, { useState } from "react";

import Layout from "../components/Layout/Layout";
import SEO from "../components/Layout/SEO";
import SectionV from "../components/Flex/SectionV";
import Box from "../components/Flex/Box";
import { Visualizer } from "../components/VisualizerSlider";
import { all_categories } from "../constants/products";

import ContactFormSection from "../components/ContactForm";
import Text from "../components/UI/Text";
import FlexV from "../components/Flex/FlexV";
import Button from "../components/UI/Button";
import FlexH from "../components/Flex/FlexH";
import colors from "../constants/colors";

const Gallery = () => {
  const [selectedId, setSelectedId] = useState(undefined);

  return (
    <Layout>
      <SEO title="Elevate3D | Gallery" />
      <SectionV style={{ marginBottom: 100 }}>
        <Box>
          <Text size="title2">Gallery of 3D digitizations</Text>
          <FlexV alignment="flex-start">
            {Object.entries(all_categories).map(([categoryName, category]) => (
              <FlexV alignment="flex-start">
                <Text size="title3">{categoryName}</Text>
                <FlexH alignment="flex-start">
                  {category.map((productId) => (
                    <Visualizer
                      prefetch={false}
                      productId={productId}
                      width={450}
                      height={450}
                      enabled={selectedId === productId}
                      key={productId}
                      onClick={() => setSelectedId(productId)}
                    />
                  ))}
                </FlexH>
              </FlexV>
            ))}
          </FlexV>
        </Box>
      </SectionV>
    </Layout>
  );
};

const styles = {
  details: {
    fontSize: "18px",
    color: colors.secondary,
    lineHeight: "25px",
  },
  check: {
    height: 15,
    width: 15,
  },
};

export default Gallery;

import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const Flex = ({
  children,
  alignment = "center",
  justify = "center",
  gap = 10,
  className,
  ...props
}) => {
  const classes = useStyles({ alignment, justify, gap });
  return (
    <div {...props} className={classNames(classes.flexDiv, className)}>
      {children}
    </div>
  );
};

export default Flex;

const useStyles = createUseStyles({
  flexDiv: {
    display: "flex",
    alignItems: ({ alignment }) => alignment,
    justifyContent: ({ justify }) => justify,
    gap: ({ gap }) => `${gap}px`,
  },
});

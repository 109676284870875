import React from "react";
import { createUseStyles } from "react-jss";
import FlexH from "./FlexH";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

const SectionH = ({ children, className, ...props }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });
  const classes = useStyles({ isSmallScreen });
  return (
    <FlexH {...props} className={classNames(classes.flexDiv, className)}>
      {children}
    </FlexH>
  );
};

export default SectionH;

const useStyles = createUseStyles({
  flexDiv: {
    width: "100%",
    padding: ({ isSmallScreen }) => (isSmallScreen ? 0 : 20),
  },
});

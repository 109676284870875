import Layout from "../components/Layout/Layout";
import SEO from "../components/Layout/SEO";
import SectionV from "../components/Flex/SectionV";
import Box from "../components/Flex/Box";

import { ContactFormSection } from "../components/ContactForm";
import Text from "../components/UI/Text";
import FlexV from "../components/Flex/FlexV";
import Button from "../components/UI/Button";
import FlexH from "../components/Flex/FlexH";
import colors from "../constants/colors";

const Pricing = () => {
  return (
    <Layout>
      <SEO title="Elevate3D | Pricing" />
      <SectionV style={{ marginBottom: 100 }}>
        <Box>
          <Text size="title2">Pricing</Text>
          <p style={styles.details}>
            We offer competitive pricing that adapts to your business needs.
            Contact us for custom requirements.
          </p>

          <FlexH alignment="flex-start">
            <PricingTable
              title="Team"
              features={[
                "Unmatched photorealism",
                "Integration to all ecommerce platforms",
                "Up to 10 hosted models",
                "10,000 monthly visitors",
              ]}
            />
            <PricingTable
              title="Ecommerce"
              fill
              features={[
                <span>
                  Everything in <i>Team</i>
                </span>,
                "Up to 200 hosted models",
                "1 million monthly visitors",
              ]}
            />
            <PricingTable
              title="Enterprise"
              features={[
                <span>
                  Everything in <i>Ecommerce</i>
                </span>,
                "Unlimited hosted models",
                "Unlimited visitors",
                "Unlimited support",
              ]}
              color="#E6E6E6"
            />
          </FlexH>
        </Box>
      </SectionV>

      <ContactFormSection />
    </Layout>
  );
};

const PricingTable = ({
  title,
  fill = false,
  features = [],
  color = colors.white,
}) => {
  const scrollToForm = () => {
    const form = document.querySelector("#request-section");
    form.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <FlexV
      style={{
        border: "1px solid #ddd",
        borderRadius: 10,
        overflow: "hidden",
        flex: 1,
        minWidth: 300,
      }}
    >
      <FlexV
        style={{
          width: "100%",
          borderBottom: "1px solid #ddd",
          backgroundColor: fill ? "#016BFF" : color,
          padding: 20,
          color: fill ? colors.white : colors.primary,
        }}
      >
        <Text size="title3" style={{ margin: 0 }}>
          {title}
        </Text>
      </FlexV>

      <FlexV style={{ padding: 20 }}>
        <FlexV alignment="flex-start">
          {features.map((feature) => (
            <FlexH flexWrap="noWrap">
              <img src="/res/check.png" style={styles.check} />
              <span>{feature}</span>
            </FlexH>
          ))}
        </FlexV>

        <Button
          color="#14C463"
          onClick={scrollToForm}
          style={{ marginTop: 10 }}
        >
          Contact us
        </Button>
      </FlexV>
    </FlexV>
  );
};

const styles = {
  details: {
    fontSize: "18px",
    color: colors.secondary,
    lineHeight: "25px",
  },
  check: {
    height: 15,
    width: 15,
  },
};

export default Pricing;

import React from "react";
import { Routes, Route } from "react-router-dom";
import "./styles/global.css";

import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Privacy from "./pages/Privacy";
import Gallery from "./pages/Gallery";
import Gallery2 from "./pages/Gallery2";
import Terms from "./pages/Terms";
import useGoogleAnalytics from "./utils/useGoogleAnalytics";

const App = () => {
  useGoogleAnalytics();

  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/waitlist" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/digitizations" element={<Gallery2 />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </ScrollToTop>
  );
};

export default App;

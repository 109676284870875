import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children, withHeader = true, onMouseMove, ...props }) => {
  return (
    <div {...props} style={styles.layout} onMouseMove={onMouseMove}>
      {withHeader && <Header />}

      {children}

      <Footer />
    </div>
  );
};

export default Layout;

const styles = {
  layout: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
  },
};

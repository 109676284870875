import React from "react";
import "./index.css";

export function Loading({ style }) {
  return (
    <div className="lds-ellipsis" style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

import React from "react";
import { createUseStyles } from "react-jss";
import colors from "../../constants/colors";
import { motion } from "framer-motion";
import classNames from "classnames";
import { Loading } from "../Loading";

const Button = ({
  children,
  secondary,
  fill,
  loading,
  noAnim,
  color = colors.coreOrange,
  rounded = 7.5,
  borderWidth = 2,
  className,
  paddingVertical = 5,
  paddingHorizontal = 20,
  style,
  onClick,
  ...props
}) => {
  const classes = useStyles({ color, rounded, borderWidth });
  return (
    <motion.button
      {...props}
      className={classNames(
        classes.button,
        secondary ? classes.secondary : classes.primary,
        fill ? classes.fill : "",
        className
      )}
      style={{
        paddingLeft: paddingHorizontal,
        paddingRight: paddingHorizontal,
        paddingTop: paddingVertical,
        paddingBottom: paddingVertical,
        ...style,
      }}
      whileHover={{ scale: noAnim ? 1.0 : 1.025 }}
      whileTap={{ scale: noAnim ? 1.0 : 0.975 }}
      onTap={onClick}
    >
      {loading ? <Loading /> : children}
    </motion.button>
  );
};

export default Button;

const useStyles = createUseStyles({
  button: {
    fontSize: 12,
    margin: 0,
    background: "none",
    boxSizing: "border-box",
    color: colors.white,
    borderRadius: ({ rounded }) => rounded,
    cursor: "pointer",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  primary: {
    backgroundColor: ({ color }) => color,
    borderColor: ({ color }) => color,
    fontWeight: 500,
    borderStyle: "solid",
    borderWidth: ({ borderWidth }) => borderWidth,
    textShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  secondary: {
    color: ({ color }) => color,
    borderColor: ({ color }) => color,
    borderStyle: "solid",
    borderWidth: ({ borderWidth }) => borderWidth,
  },
  fill: {
    width: "100%",
  },
});

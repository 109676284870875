import React, { useState } from "react";

import Layout from "../components/Layout/Layout";
import SEO from "../components/Layout/SEO";
import SectionV from "../components/Flex/SectionV";
import Box from "../components/Flex/Box";
import { Visualizer } from "../components/VisualizerSlider";
import { selected_categories } from "../constants/products";

import Text from "../components/UI/Text";
import FlexV from "../components/Flex/FlexV";
import FlexH from "../components/Flex/FlexH";

const Gallery2 = () => {
  const [selectedId, setSelectedId] = useState(undefined);

  return (
    <Layout>
      <SEO title="Elevate3D | Digitizations" />
      <SectionV style={{ marginBottom: 100 }}>
        <Box>
          <Text size="title2">Selection of 3D digitizations</Text>
          <FlexV alignment="flex-start">
            {Object.entries(selected_categories).map(
              ([categoryName, category]) => (
                <FlexV alignment="flex-start">
                  <Text size="title3">{categoryName}</Text>
                  <FlexH alignment="flex-start">
                    {category.map((productId) => (
                      <Visualizer
                        prefetch={false}
                        productId={productId}
                        width={450}
                        height={450}
                        enabled={selectedId === productId}
                        key={productId}
                        onClick={() => setSelectedId(productId)}
                      />
                    ))}
                  </FlexH>
                </FlexV>
              )
            )}
          </FlexV>
        </Box>
      </SectionV>
    </Layout>
  );
};

export default Gallery2;

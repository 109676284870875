import React from "react";
import colors from "../../constants/colors";
import { Link } from "react-router-dom";
import Spacer from "../Flex/Spacer";
import Button from "../UI/Button";
import Box from "../Flex/Box";
import FlexH from "../Flex/FlexH";
import { useMediaQuery } from "react-responsive";

const Header = ({ ...props }) => {
  const isTinyScreen = useMediaQuery({ query: "(max-width: 340px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <header {...props} style={styles.header}>
      <Box style={isSmallScreen ? { paddingLeft: 5, paddingRight: 5 } : {}}>
        <FlexH gap={10}>
          <Link style={styles.logoText} to="/">
            <img style={styles.logoImg} src="/icons/elevate3d.svg" alt="logo" />
            <span className="medium">Elevate3D</span>
          </Link>
          <Spacer />
        </FlexH>
      </Box>
    </header>
  );
};

export default Header;

const styles = {
  header: {
    boxShadow: "0px 0px 15px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: colors.white,
    width: "100%",
    zIndex: 100,
    overflow: "hidden",
    position: "relative",
  },
  logoText: {
    fontSize: 26,
    padding: "10px 0",
    display: "flex",
    alignItems: "center",
  },
  logoImg: {
    marginRight: 10,
    height: 35,
  },
};

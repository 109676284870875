import React from "react";
import classNames from "classnames";

const Text = ({
  children,
  size = "body",
  weight = "regular",
  className,
  ...props
}) => {
  const allClassNames = classNames(size, weight, className);

  switch (size) {
    case "largeTitle":
      return (
        <h1 {...props} className={classNames(allClassNames, "large")}>
          {children}
        </h1>
      );
    case "title1":
      return (
        <h1 {...props} className={allClassNames}>
          {children}
        </h1>
      );
    case "title2":
      return (
        <h2 {...props} className={allClassNames}>
          {children}
        </h2>
      );
    case "title3":
      return (
        <h3 {...props} className={allClassNames}>
          {children}
        </h3>
      );
    default:
      return (
        <span {...props} className={allClassNames}>
          {children}
        </span>
      );
  }
};

export default Text;

import Layout from "../components/Layout/Layout";
import SEO from "../components/Layout/SEO";
import SectionV from "../components/Flex/SectionV";
import Box from "../components/Flex/Box";
import ReactMarkdown from "react-markdown";

const policy = `
# Privacy Policy
Last Update: April 12th 2022

Your privacy is extremely important to us and our Service was developed from the ground up having this in mind.

This Privacy Policy is an integral part of the Elevate3D [Terms](/terms) and applies to every visitor, user or other individual
accessing our Services.
In the following, we describe when, where, how and why we collect information abput you, the conditions under which we may
disclose it to others and your rights with respect to your personal data.

### 1. Information we collect
When you use the Service, we may collect the following information about you:

* **Service usage information**, such as your preferred language and the date you last used the service.

* **Device data**, such as your device operating system, device ID, Google Advertising ID.

* We may also use technologies like pixels to collect information about how you use Elevate3D.


### 2. How we use Information
We may use the information to:

* Monitor the effectiveness of our Services.

* Perform troubleshooting and fix technical issues.

* Analyze trends and usage of our Service.


### 3. How we share Information
Our team may have access to your data. Furthermore, Elevate3D cooperates with other service providers in order to provide the highest-quality services.
Therefore, we may share the Information with the following platforms:
 
* **Amazon Web Services**: a secure web hosting and cloud provider.

* **Firebase**: a mobile platform from Google, helping Elevate3D develop high-quality applications.

Elevate3D does not control third party platforms or services, and is not responsible for their actions. We encourage you 
to read the privacy policies of these platforms to learn how your usage data may be used by these providers.

Your data is hosted by servers of Amazon Web Services, USA.

It may be transferred outside the European Union. This transfer is secured by the following safeguards:
* Either this data is transferred to a country that has been deemed to offer an adequate level of protection by a
decision of the European Commission;

* Or we have entered into a specific contract with our subcontractors related to the transfer of your data outside the European
Union, based on standard contractual clauses between a data controller and a data processor approved by the European Commission.


### 4. How we store Information

* We retain information no longer than necessary to fulfill the purposed Elevate3D has described
in this Privacy Policy. You can ask at anytime to delete your information by contacting us at [hello@elevate3d.ai](mailto:hello@elevate3d.ai).


### 5. Your rights regarding Information

You have the following rights with regard to your personal data:

* **Right to be informed**: This is precisely why we have drafted this privacy policy.

* **Right of access**: You have the right to access all your personal data at any time.

* **Right of rectification**: You have the right to rectify your inaccurate, incomplete or obsolete personal data at any time.

* **Right to restriction of processing**: You have the right to restrict the processing of your personal data in certain
cases stated in art.18 of the GDPR.

* **Right to be forgotten**: You have the right to demand that your personal data be deleted and to
prohibit any future collection.

* **Right to file a complaint** to a competent supervisory authority (in France, the CNIL), if you consider that the
processing of your personal data constitutes a breach of applicable regulations.

* **Right to define instructions** related to the retention, deletion and communication of your personal data after your death.

* **Right to data portability**: You have the right to receive the personal data you have provided us in a standard
machine-readable format and to require their transfer to the recipient of your choice.

* **Right to object**: You have the right to object to the processing of your personal data. Please note however that we may
continue to process your personal data despite this opposition for legitimate reasons or for the defense of legal claims.

You can exercise these rights by writing us using the contact details below. We may ask you on this occasion to provide us with additional information or documents to prove your identity.

### 6. Children's Privacy

The privacy of children is one of our major concerns.
 
**General age limitation**. At Elevate3D we can provide you the Services only in case you are aged 13 or older and any use or
access to the Service by anyone under thirteen (13) is strictly prohibited and in violation of the Terms and these Policy.
If you are under 18 you will need to get your parent’s/guardian’s permission before providing any private information to Elevate3D.
We reserve the right to request reasonable proof of such permission, and you must provide such proof within a reasonable
period of time, but no later than in three (3) business days following such request. Shall we not receive any answer or
proofs concerning your maturity, we reserve the right to terminate your access to the Services.
 
**Age limitation for EU residents**. Due to requirements of the EU General Data Protection Regulation you shall be at least
16 years old in order to use our Services. To the extent prohibited by applicable law, we do not allow use of the
Service by the EU residents younger than 16 years old, unless with the parent’s/guardian’s permission as provided above.
 
If we unintentionally collect personal data, find any content with, or related to a child under age 13 (or 16 in the EU),
we will delete such information as quickly as possible. Besides, if you are aware of anyone younger than 13 (or 16 in the EU)
using our Services, please contact us at [hello@elevate3d.ai](mailto:hello@elevate3d.ai)

### 7. Change to our Privacy Policy
Our Privacy Policy can be changed at any time to comply with the applicable laws of the functioning of our Services.
Please refer regularly to the latest version of our Privacy Policy. Additionally, we also notify you when using the
Services. We consider you have accepted all the amendments made if within one week of such notification, you continue
to use our Services.


### 8. Contacts
For any additional question regarding the Privacy Policy, please contact us at [hello@elevate3d.ai](mailto:hello@elevate3d.ai) or
write us to:

Elevate3D, GmbH  
Grabenstrasse 20, 6300 Zug
Switzerland
`;

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Elevate3D | Privacy Policy" />
      <SectionV>
        <Box>
          <ReactMarkdown children={policy} />
        </Box>
      </SectionV>
    </Layout>
  );
};

export default Privacy;

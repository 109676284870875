import React from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import classNames from "classnames";

const Clickable = ({
  children,
  noAnim,
  className,
  style,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  return (
    <motion.span
      {...props}
      className={classNames(classes.button, className)}
      style={style}
      whileHover={{ scale: noAnim ? 1.0 : 1.025 }}
      whileTap={{ scale: noAnim ? 1.0 : 0.975 }}
      onTap={onClick}
    >
      {children}
    </motion.span>
  );
};

export default Clickable;

const useStyles = createUseStyles({
  button: {
    cursor: "pointer",
  },
});

import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import ReactModal from "react-modal";
import colors from "../constants/colors";
import { AiFillCloseCircle } from "react-icons/ai";

ReactModal.setAppElement("#root");

const Modal = ({ children, onRequestClose, open }) => {
  return (
    <ReactModal isOpen={open} onRequestClose={onRequestClose} style={styles}>
      <>
        <AiFillCloseCircle
          style={styles.close}
          onMouseDown={onRequestClose}
          onTouchStart={onRequestClose}
        />
        {children}
      </>
    </ReactModal>
  );
};

export default Modal;

const styles = {
  close: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 10,
    width: 45,
    height: 45,
    cursor: "pointer",
    color: colors.secondary,
  },

  overlay: {
    zIndex: 999,
    backgroundColor: "#000a",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const prospectURLs = [
  "shopify",
  "adidas",
  "nike",
  "marzio",
  "feetfirst",
  "footway",
  "deichmann",
  "scorett",
  "bubbleroom",
  "spartoo",
  "brandosa",
  "klingel",
  "eurosko",
  "johanssons",
  "skoman",
  "stooks",
  "mokvistskor",
  "froviskor",
  "bergqvistskor",
  "hakanssons",
  "skechers",
  "nelly",
  "ahlens",
  "boozt",
  "miinto",
  "skoringen",
  "s-d-v",
  "sarenza",
  "footstore",
  "cornettsko",
  "magasin",
  "juul-sko",
  "shoe4you",
  "humanic",
  "bally",
  "bally",
  "bally",
  "idealofsweden",
  "idealofsweden",
  "idealofsweden",
  "aboutyou",
  "otto",
  "otto",
  "otto",
  "otto",
  "bianco",
  "ecco",
  "ecco",
  "hi-tec",
  "reebok",
  "adidas",
  "nike",
  "puma",
  "geox",
  "kappastore",
  "lotto",
  "superga",
  "tods",
  "acemarks",
  "ferragamo",
  "cheaney",
  "idrese",
  "taftclothing",
  "johnlobb",
  "aubercy",
  "vass-shoes",
  "maglieriapelle",
  "saintcrispins",
  "kielman",
  "santosshoes",
  "hoka",
  "deckers",
  "salomon",
  "diadora",
  "on-running",
  "haglofs",
  "hellyhansen",
  "dinsko",
  "veepee",
  "chanel",
  "hermes",
  "bestseller",
  "celine",
  "farfetch",
  "louis",
  "vuiton",
  "boss",
  "myrqvist",
  "newbalance",
  "vans",
  "myntra",
  "nordstrom",
  "houseoffraser",
  "wardow",
  "myer",
  "shoppersstop",
  "ajio",
  "nextplc",
  "flipkart",
  "johnlewis",
  "coach",
  "katespade",
  "aldoshoes",
  "zappos",
  "justfab",
  "tatacliq",
  "zalando",
  "hm",
  "cos",
  "modcloth",
  "asos",
  "macys",
  "koala",
  "famousfootwear",
  "dsw",
  "schuh",
  "shoecarnival",
  "footlocker",
  "ssense",
  "endclothing",
  "bloomingdales",
  "urbanoutfitters",
  "coggles",
  "matchesfashion",
  "saksfifthavenue",
  "sneakersnstuff",
  "theiconic",
  "chloe",
  "dior",
  "gucci",
  "prada",
  "louisvuitton",
  "fendi",
  "obag",
].map((url) => `/${url}`);

export default prospectURLs;

import React from "react";
import { createUseStyles } from "react-jss";
import { AiOutlineInfoCircle } from "react-icons/ai";
import colors from "../constants/colors";
import classNames from "classnames";

const Alert = ({
  children,
  fill,
  className,
  paddingVertical = 10,
  paddingHorizontal = 20,
  style,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div
      {...props}
      className={classNames(classes.alert, fill ? classes.fill : "", className)}
      style={{
        paddingLeft: paddingHorizontal,
        paddingRight: paddingHorizontal,
        paddingTop: paddingVertical,
        paddingBottom: paddingVertical,
        ...style,
      }}
    >
      <AiOutlineInfoCircle color="#1890ff" style={{ marginRight: 8 }} />
      <div style={{ width: "100%" }}>{children}</div>
    </div>
  );
};

export default Alert;

const useStyles = createUseStyles({
  alert: {
    display: "flex",
    alignItems: "center",
    borderRadius: 5,
    color: "#262a2b",
    border: "1px solid",
    borderColor: "#91d5ff",
    backgroundColor: "#e6f7ff",
  },
  fill: {
    width: "100%",
  },
});

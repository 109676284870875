import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import prospectURLs from "../constants/prospects";

export default function useGoogleAnalytics() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isRedirect, setIsRedirect] = useState(false);

  React.useEffect(() => {}, []);

  const sendNotification = (url) => {
    fetch(
      `https://api.elevate3d.ai/v1/analytics?` +
        new URLSearchParams({
          url,
        }),
      {
        method: "POST",
      }
    );
  };

  React.useEffect(() => {
    if (isRedirect) {
      return setIsRedirect(false);
    }

    const currentPath = location.pathname + location.search;

    if (prospectURLs.includes(location.pathname)) {
      setIsRedirect(true);

      sendNotification(currentPath);

      navigate("/");
    }

    if (["/gallery"].includes(location.pathname)) {
      sendNotification(currentPath);
    }

    window.gtag("set", "page_path", currentPath);
    window.gtag("event", "pageview");
  }, [location]);
}

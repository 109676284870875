export const categories = {
  Handbags: {
    color: "#9CD84F",
    productIds: ["6324a7a9f20635c60adfbbb5", "6324a7d9f20635c60adfbbb7"],
  },
  "High Heels": {
    color: "#EC76D9",
    productIds: ["6324aa3df20635c60adfbbb8", "6324aa44f20635c60adfbbb9"],
  },
  Shoes: {
    color: "#46BAD3",
    productIds: ["6334360ba803ae1297115d78", "6334362ea803ae1297115d7b"],
  },
};

export const all_categories = {
  Handbags: [
    "6324a7a9f20635c60adfbbb5",
    "6324a7d9f20635c60adfbbb7",
    "6324a7c3f20635c60adfbbb6",
  ],
  "Women shoes": ["6324aa3df20635c60adfbbb8", "6324aa44f20635c60adfbbb9"],
  "Men shoes": [
    "6334362ea803ae1297115d7b",
    "63343625a803ae1297115d7a",
    "6334360ba803ae1297115d78",
  ],
  Luxury: ["627c038f5bdbaad0bd15dd0d", "63303271eefc0073f82d9ae7"],
  "Stuffed animals & toys": [
    "62726cc87f43af7837164302",
    "62729992b613cf0f0f5c6e5f",
    "6273006ab613cf0f0f5c6e60",
  ],
  Other: ["62731222438fe18d6ad4ffb1", "627bdd73fd7e52dca5fa1727"],
  Figurines: [
    "627be925fd7e52dca5fa172a",
    "627be8dffd7e52dca5fa1728",
    "627be908fd7e52dca5fa1729",
  ],
};

export const selected_categories = {
  Handbags: ["6324a7d9f20635c60adfbbb7", "6324a7c3f20635c60adfbbb6"],
  "Women shoes": ["6324aa3df20635c60adfbbb8", "6324aa44f20635c60adfbbb9"],
  "Men shoes": ["6334362ea803ae1297115d7b", "63343625a803ae1297115d7a"],
  Other: ["627c038f5bdbaad0bd15dd0d", "62726cc87f43af7837164302"],
};

export default categories;

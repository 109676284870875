import React from "react";
import { createUseStyles } from "react-jss";
import Flex from "./Flex";
import classNames from "classnames";

const FlexH = ({ children, flexWrap = "wrap", className, ...props }) => {
  const classes = useStyles({ flexWrap });
  return (
    <Flex {...props} className={classNames(classes.flexDiv, className)}>
      {children}
    </Flex>
  );
};

export default FlexH;

const useStyles = createUseStyles({
  flexDiv: {
    flexWrap: ({ flexWrap }) => flexWrap,
  },
});
